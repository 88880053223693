.ContentHeader {
	background-color: #F8F8F8;
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	padding: 1rem 0.5rem !important;
	border-top: 1px solid #e5e5e5;
	border-bottom: 1px solid #EFEFEF;
}

.ContentHeader .breadcrumb {
	font-size: 0.75rem;
	margin-bottom: 0.5rem;
}

.ContentHeader .header {
	margin: 0;
	font-size: 1.4rem !important;
}